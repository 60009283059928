import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Navbar.scss';
import {useNavigate} from 'react-router-dom';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [isSpinning, setIsSpinning] = useState(true);
  return (
    <nav className="app__navbar">





      <ul className="app__navbar-links">
      <div className={isSpinning && 'container'}>
        <div className={isSpinning && 'wrap'}>
          <div className={isSpinning && 'circle horizontal c1'}>
            <div className={isSpinning && 'wrap-electron'}>
              <div className={isSpinning && 'circle electron'}></div>
            </div>
          </div>
          <div className={isSpinning && 'circle vertical c1'}>
            <div className={isSpinning && 'wrap-electron'}>
              <div className={isSpinning && 'circle electron'}></div>
            </div>
          </div>
        </div>
        <div className={isSpinning && 'wrap r'}>
          <div className={isSpinning && 'circle horizontal c2'}>
            <div className={isSpinning && 'wrap-electron'}>
              <div className={isSpinning && 'circle electron'}></div>
            </div>
          </div>
          <div className={isSpinning && 'circle vertical c2'}>
            <div className={isSpinning && 'wrap-electron'}>
              <div className={isSpinning && 'circle electron'}></div>
            </div>
          </div>
          <div className={isSpinning && 'circle center'}></div>
        </div>
      </div>
        {['about', 'experience', 'apps', 'publications','skills', 'contact','resume'].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            {item === 'resume' ? (
              <a href="/resume/resume.pdf">{item}</a>
            ) : (
            <a href={`#${item}`}>{item}</a>
            )}


          </li>
        ))}
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {['about','experience', 'apps', 'publications','skills', 'contact','resume'].map((item) => (
                <li key={item}>
      
                  {item === 'resume' ? (

                    <a href="/resume/resume.pdf" onClick={() => setToggle(false)}>
                      {item}
                    </a>
                  ) : (
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {item}
                    </a>
                  )}


                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
