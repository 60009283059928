import React, { useState,useEffect } from 'react';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Projects.scss';
import Viztein from 'viztein';



function Projects() {




    const data = {
    	filename: "https://files.rcsb.org/download/5MAF.pdb",

    };

    return (
      <>

        <h2 className="head-text">Host-Guest Systems</h2>

        <Viztein
         data={data}
         viewportStyle={{width:'600px',height: '600px', backgroundColor: 'white'}}

        />


      </>
    )
  }




export default AppWrap(
  MotionWrap(Projects, 'app__footer'),
  'work-examples',
  'app__whitebg',
);
