let timelineElements = [
  {
    id: 1,
    title: "Ph.D. - Computational Chemistry at University of Texas at Austin",
    location: "Austin, Texas",
    description:
      "• Developed and designed an automated open source molecular modelling platform for high throughput ligand screening and protein-ligand, DNA/RNA-ligand simulations and free energy calculations (Poltype 2). ",
    nextdescription:
      "• Assigning correct chemical parameters is very complicated! This tool has enabled researchers (non-AMOEBA experts) world wide to run simulations on protein-ligand, DNA/RNA-ligand systems with the AMOEBA model towards more accurate chemical property predictions. ",
    finaldescription:
        "• Qubit Pharmaceuticals uses this software routinely in their drug discovery pipeline.",
    otherbuttonText: "Poltype 2",
    otherbuttonFile: "https://github.com/TinkerTools/poltype2",
    anotherbuttonText: "AMOEBA",
    anotherbuttonFile: "https://pubs.acs.org/doi/10.1021/jp910674d",
    date: "August 2017 - December 2022",
    icon: "texas",
  },
  {
    id: 2,
    title: "Machine Learning Engineer Intern - Sandia National Labs",
    location: "Albuquerque, New Mexico",
    description:
      "• Automated database generation of ab initio quantum data for training a neural network to predict molecular electron densities towards applications of quantum accurate and fast biomolecular simulations by computing atomic forces via predicted densities and the Hellmann–Feynman theorem.",
    nextdescription:
      "• This experience enabled me to hone skills which I have then used to mentor NSF REU undergraduates in machine learning projects such as the prediction of transfection rates for gene delivery mechanisms.",
    evenanotherbuttonText: "ML Electron Densities",
    evenanotherbuttonFile: "https://arxiv.org/abs/2201.03726",
    buttonText: "NSF REU",
    buttonFile: "/misc/Poster.pdf",
    date: "June 2020 - August 2020",
    icon: "sandia",
  },
  {
    id: 3,
    title: "Masters - Biomedical Engineering at University of Texas at Austin",
    location: "Austin, Texas",
    description:
      "• GPA=3.79.",
    buttonText: "Transcripts",
    buttonFile: "/transcripts/uttranscript.pdf",
    date: "August 2017 - May 2019",
    icon: "texas",
  },
  {
    id: 4,
    title: "B.S. - Physics & Mathematics at Purdue University",
    location: "West Lafayette, IN",
    description:
      "• GPA=3.76.",
    buttonText: "Transcripts",
    buttonFile: "/transcripts/purduetranscript.pdf",
    date: "August 2013- May 2017",
    icon: "purdue",
  },

];

export default timelineElements;
