import React, {useState,useEffect} from 'react';
import { Timeline, Footer, About, Skills, Testimonial, Apps, Landing, Publications,WorkSummary,Background,Projects,Progress,Resume } from './container';
import { Navbar } from './components';
import './App.scss';
import RingLoader from "react-spinners/RingLoader";
import { Route, BrowserRouter, Switch } from "react-router-dom";

function App () {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
  window.addEventListener("resize", updateMedia);
  return () => window.removeEventListener("resize", updateMedia);
});

  const [loading,setLoading] = useState(false);
  useEffect(()=>{

   setLoading(true)
   setTimeout(()=>{
     setLoading(false)
   },2000)
 },[])
 const override: CSSProperties = {
   display: "block",
   margin: "0 auto",
   borderColor: "red",
   top:"200px"
 };
  return (
  <>
  { loading ?
    <RingLoader color={"#0000FF"} loading={loading} cssOverride={override} size={150} />
  :

  <BrowserRouter>
    <div className="app">

      <Switch>
      <Route exact path="/">
      <Navbar />
      <Progress/>
      <About />
      <Timeline />
      <Apps />
      <Publications />
      <Skills />
      <Footer />
      
      </Route>
      <Route path="/intro">
        <Background />

      </Route>
      </Switch>
      </div>
    </BrowserRouter>




  }

  </>

);
};

export default App;
