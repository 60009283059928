import React, { useState, useEffect,useRef } from 'react';
import { AiFillEye, AiFillGithub } from 'react-icons/ai';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Publications.scss';
import Articles from "./articles.svg";


const Publications = () => {
  const [isHovering, setIsHovering] = useState(false);

const handleMouseOver = () => {
  setIsHovering(true);
};

const handleMouseOut = () => {
  setIsHovering(false);
};

  const [publications, setPublications] = useState([]);
  const [activeFilter, setActiveFilter] = useState('First Author');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [filterPublication, setFilterPublication] = useState(publications.filter((publication) => publication.tags.includes('First Author')));
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      const query = '*[_type == "publications"]';

      client.fetch(query).then((data) => {
        setPublications(data);
        setFilterPublication(data);
      });
    } else {
      isMounted.current = true;
      const query = '*[_type == "publications"]';

      client.fetch(query).then((data) => {
        setPublications(data.filter((data) => data.tags.includes('First Author')));
        setFilterPublication(data.filter((data) => data.tags.includes('First Author')));
      });
    };

  }, [activeFilter]);



  const handlePublicationFilter = (item) => {

    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'All') {
        setFilterPublication(publications);
      } else {
        setFilterPublication(publications.filter((publication) => publication.tags.includes(item)));
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">Publications</h2>
      <img className='articles' src={Articles}/>
      <div className="app__publication-filter">
        {['First Author','Co-Author','Review', 'All'].map((item, index) => (
          <div
            key={index}
            onClick={() => handlePublicationFilter(item)}
            className={`app__publication-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__publication-portfolio"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {filterPublication.map((publication, index) => (
          <div className="app__publication-item app__flex" key={index} >
            <div
              className="app__publication-img app__flex"

            >
              <img src={urlFor(publication.imgUrl)} alt={publication.name} />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                className="app__publication-hover app__flex"
              >
                <a href={publication.projectLink} target="_blank" rel="noreferrer">

                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.90] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>

              </motion.div>
            </div>

            <div className="app__publication-content app__flex">
              <h4 className="bold-text">{publication.title}</h4>
              {isHovering ? (
              <p className="p-text" style={{ marginTop: 10 }}>{publication.description}</p>
              ) : (
                <p className="newp-text" style={{ marginTop: 10 }}>{publication.description}</p>
              )}
              <div className="app__publication-tag app__flex">
                <p className="p-text">{publication.tags[0]}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
};



export default AppWrap(
  MotionWrap(Publications, 'app__publications'),
  'publications',
  'app__whitebg',
);
