import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ReactTooltip from 'react-tooltip';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Skills.scss';
import TagCloud from 'react3dtagcloud';
import WebDev from "./webdev.svg";



const Skills = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
  window.addEventListener("resize", updateMedia);
  return () => window.removeEventListener("resize", updateMedia);
});


  const [skills, setSkills] = useState([]);
  const [langskills, setLangSkills] = useState([]);
  const [dataskills, setDataSkills] = useState([]);
  useEffect(() => {

    const skillsQuery = '*[_type == "skills"]';
    client.fetch(skillsQuery).then((data) => {
      setSkills(data);
    });

    const langskillsQuery = '*[_type == "langskills"]';
    client.fetch(langskillsQuery).then((data) => {
      setLangSkills(data);
    });
    const dataskillsQuery = '*[_type == "dataskills"]';
    client.fetch(dataskillsQuery).then((data) => {
      setDataSkills(data);
    });


  }, []);

  const tagName = ['Javascript','HTML','CSS','React','Python','Mathematica','Matlab','Bash','Git','Sanity'];

  let data = [{ x: 'Javascript', y: 50 }, { x: 'HTML', y: 80 }, { x: 'CSS', y: 60 }, { x: 'React', y: 60 },
{ x: 'Python', y: 80 }, { x: 'Mathematica', y: 40 },{ x: 'Matlab', y: 40 },{ x: 'Bash', y: 60 },{ x: 'Git', y: 50 },{ x: 'Sanity', y: 60 }];


  return (
    <>
      <h2 className="head-text">Languages I Speak</h2>
      <img className='webdev' src={WebDev}/>
      {isDesktop ? (
      <div className="tagcloud">
		  <TagCloud tagName={tagName}></TagCloud>
      </div>
    ) : (
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {langskills.map((langskill) => (
            <a href={langskill.website}>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={langskill.name}

            >
              <div
                className="app__flex"
                style={{ backgroundColor: langskill.bgColor }}
              >
                <img src={urlFor(langskill.icon)} alt={langskill.name} />
              </div>
              <p className="p-text">{langskill.name}</p>
            </motion.div>
            </a>
          ))}
        </motion.div>
      </div>
    )}




    <h2 className="anotherhead-text">Data Science Software</h2>
    <div className="app__skills-container">
      <motion.div className="app__skills-list">
        {dataskills.map((skill) => (
          <a href={skill.website}>
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5 }}
            className="app__skills-item app__flex"
            key={skill.name}

          >
            <div
              className="app__flex"
              style={{ backgroundColor: skill.bgColor }}
            >
              <img src={urlFor(skill.icon)} alt={skill.name} />
            </div>
            <p className="p-text">{skill.name}</p>
          </motion.div>
          </a>
        ))}
      </motion.div>
    </div>

      <h2 className="anotherhead-text">Chemistry Software</h2>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.map((skill) => (
            <a href={skill.website}>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name}

            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
            </a>
          ))}
        </motion.div>
      </div>


    </>
  );
};



export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__whitebg',
);
