import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './WorkSummary.scss';
import { urlFor, client } from '../../client';

const WorkSummary = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  const [isHovering, setIsHovering] = useState(false);

const handleMouseOver = () => {
  setIsHovering(true);
};

const handleMouseOut = () => {
  setIsHovering(false);
};

  return (
    <>
      <h2 className="head-text">Work Summary</h2>

      <div className="app__profiles" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>

        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}

          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
            {isHovering ? (
            <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
          ) : (
            <p className="newp-text" style={{ marginTop: 10 }}>{about.description}</p>
            )}
          </motion.div>
        ))}
      </div>

    </>
  );
};

export default AppWrap(
  MotionWrap(WorkSummary, 'app__about'),
  'work-summary',
  'app__whitebg',
);
