import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './Background.scss';
import { urlFor, client } from '../../client';
import 'katex/dist/katex.min.css'
import Latex from 'react-latex-next'
import Viztein from 'viztein';

import ReactFlow, { useNodesState, useEdgesState, addEdge } from 'react-flow-renderer';

const initialNodes = [
  {
    id: 'horizontal-1',
    sourcePosition: 'right',
    type: 'input',
    className: 'dark-node',
    data: { label: 'Ligand' },
    position: { x: 0, y: 89 },
  },
  {
    id: 'horizontal-2',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Parameterize Ligand using AMOEBA' },
    position: { x: 250, y: 80 },
  },
  {
    id: 'horizontal-3',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Molecular Dynamics using AMOEBA' },
    position: { x: 500, y: 80 },
  },
  {
    id: 'horizontal-4',
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Binding Affinity Estimation' },
    position: { x: 750, y: 80 },
  },

];

const initialEdges = [
  {
    id: 'horizontal-e1-2',
    source: 'horizontal-1',
    type: 'smoothstep',
    target: 'horizontal-2',
    animated: true,
  },
  {
    id: 'horizontal-e2-3',
    source: 'horizontal-2',
    type: 'smoothstep',
    target: 'horizontal-3',
    animated: true,
  },
  {
    id: 'horizontal-e3-4',
    source: 'horizontal-3',
    type: 'smoothstep',
    target: 'horizontal-4',
    animated: true,
  },
];

const buttonStyle = { position: 'absolute', right: 10, top: 30, zIndex: 4 };




const Intro = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  const data = {
    filename: "https://files.rcsb.org/download/5MAF.pdb",

  };

  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
  window.addEventListener("resize", updateMedia);
  return () => window.removeEventListener("resize", updateMedia);
  });

  const [formulas, setFormulas] = useState([]);

  useEffect(() => {
    const query = '*[_type == "formulas"]';

    client.fetch(query).then((data) => {
      setFormulas(data);
    });
  }, []);


    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const onConnect = (params) => setEdges((els) => addEdge(params, els));
    const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <>
      <h2 className="head-text">Introduction</h2>

      <br />
      <br />
      <h3>The Atomic Multipole Optimized Energetics for Biomolecular Applications (AMOEBA) Model</h3>
      <br />
      <Latex>{'$U_{AMOEBA} = U_{angle}+U_{bond}+U_{vdw}+U_{torsion}+U_{perm-ele}+..$'}</Latex>
      <br />

      <div className="app__profiles">

        {formulas.map((formula, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={formula.title + index}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <img src={urlFor(formula.imgUrl)} alt={formula.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{formula.title}</h2>
             {isHovering ? (
            <Latex>{'$' + formula.portableTextWithLatex[1]['body'] + '$'}</Latex>
              ) : (
            <Latex> {}</Latex>
            )}


          </motion.div>
        ))}
      </div>
      <br />
      <br />

      {isDesktop ? (
      <>
      <p>To accurately estimate host-guest binding affinity, ligand parameters must be derived from a combination of ab initio quantum mechanical target data and experimental condensed phase property data to adequately model the unique chemistry. </p>
      <ReactFlow
      style={{ height: 300 }}
      nodes={nodes}
      edges={edges}
      fitView

    >

    </ReactFlow>
    <h2 className="head-text">Host-Guest Systems</h2>

    <Viztein
     data={data}
     viewportStyle={{width:'600px',height: '600px', backgroundColor: 'white'}}

    />


    </>

  ) : (
    <div></div>
  )}
  <h2 className="head-text">Work Summary</h2>

  <div className="app__profiles" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>

    {abouts.map((about, index) => (
      <motion.div
        whileInView={{ opacity: 1 }}
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.5, type: 'tween' }}
        className="app__profile-item"
        key={about.title + index}

      >
        <img src={urlFor(about.imgUrl)} alt={about.title} />
        <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
        {isHovering ? (
        <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
      ) : (
        <p className="newp-text" style={{ marginTop: 10 }}>{about.description}</p>
        )}
      </motion.div>
    ))}
  </div>

    </>
  );
};

export default AppWrap(
  MotionWrap(Intro, 'app__about'),
  'intro',
  'app__whitebg',
);
