
import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';

import { ReactComponent as WorkIcon } from "./work.svg";
import { ReactComponent as SchoolIcon } from "./school.svg";
import { ReactComponent as PurdueIcon } from "./purdue.svg";
import { ReactComponent as TexasIcon } from "./texas.svg";
import { ReactComponent as SandiaIcon } from "./sandia.svg";

import timelineElements from "./timelineElements";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";
import Certifcate from "./certificate.svg";

function Timeline() {
  let workIconStyles = { background: "#06D6A0" };
  let schoolIconStyles = { background: "#f9c74f" };
  let purdueIconStyles = { background: "#f9c74f" };
  let texasIconStyles = { background: "#CC5500" };
  let sandiaIconStyles = { background: "#34bdeb" };

  return (
    <div>
      <h2 className="head-text">Experience</h2>
      <img className='certificate' src={Certifcate}/>
      <VerticalTimeline>
        {timelineElements.map((element) => {
          let isWorkIcon = element.icon === "work";
          let isPurdueIcon = element.icon === "purdue";
          let isTexasIcon = element.icon === "texas";
          let isSandiaIcon = element.icon === "sandia";
          let showButton =
            element.buttonText !== undefined &&
            element.buttonText !== null &&
            element.buttonText !== "";

          let showOtherButton =
              element.otherbuttonText !== undefined &&
              element.otherbuttonText !== null &&
              element.otherbuttonText !== "";

          let showAnotherButton =
              element.anotherbuttonText !== undefined &&
              element.anotherbuttonText !== null &&
              element.anotherbuttonText !== "";
          let showEvenAnotherButton =
              element.evenanotherbuttonText !== undefined &&
              element.evenanotherbuttonText !== null &&
              element.evenanotherbuttonText !== "";

          return (
            <VerticalTimelineElement
              key={element.key}
              date={element.date}
              dateClassName="date"


              iconStyle={isWorkIcon === true ? (
                workIconStyles
              ) : isPurdueIcon === true ? (
                purdueIconStyles
              ) : isTexasIcon === true ? (
                texasIconStyles
              ) : isSandiaIcon === true ? (
                sandiaIconStyles
              ) : (
                schoolIconStyles
              )}
              icon={isWorkIcon === true ? (
                <WorkIcon />
              ) : isPurdueIcon === true ? (
                <PurdueIcon />
              ) : isTexasIcon === true ? (
                <TexasIcon />
              ) : isSandiaIcon === true ? (
                <SandiaIcon />
              ) : (
                <SchoolIcon />
              )}
              id={element.id}
            >
              <h3 className="vertical-timeline-element-title">
                {element.title}
              </h3>
              <h5 className="vertical-timeline-element-subtitle">
                {element.location}
              </h5>
              <p id="description">{element.description}</p>
              <p id="description">{element.nextdescription}</p>
                <p id="description">{element.finaldescription}</p>
              <div className='allbuttons'>
              {showButton && (
                <a
                  className={`button ${
                    isWorkIcon ? "workButton" : "schoolButton"
                  }`}
                  href={element.buttonFile}
                  download
                >
                  {element.buttonText}
                </a>

              )}
              {showOtherButton && (
                <a
                  className={`button ${
                    isWorkIcon ? "workButton" : "schoolButton"
                  }`}
                  href={element.otherbuttonFile}

                >
                  {element.otherbuttonText}
                </a>

              )}
              {showAnotherButton && (
                <a
                  className={`anotherbutton ${
                    isWorkIcon ? "workButton" : "schoolButton"
                  }`}
                  href={element.anotherbuttonFile}

                >
                  {element.anotherbuttonText}
                </a>

              )}
              {showEvenAnotherButton && (
                <a
                  className={`evenanotherbutton ${
                    isWorkIcon ? "workButton" : "schoolButton"
                  }`}
                  href={element.evenanotherbuttonFile}

                >
                  {element.evenanotherbuttonText}
                </a>

              )}
              </div>

            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
}




export default AppWrap(
  MotionWrap(Timeline, 'app__about'),
  'experience',
  'app__whitebg',
);
