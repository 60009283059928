import React from 'react';
import { BsTwitter, BsInstagram, BsLinkedin, BsGithub,BsFillPhoneFill} from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import {SiGooglescholar} from 'react-icons/si'
import {GrMail,GrDocumentText} from 'react-icons/gr';

const SocialMedia = () => (
  <div className="app__social">
    
  </div>
);

export default SocialMedia;
