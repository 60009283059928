import React, { useState, useEffect,useRef } from 'react';
import { AiFillEye, AiFillGithub } from 'react-icons/ai';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Resume.scss';

const Resume = () => {
    return (

      <>
      </>



      );

};



export default AppWrap(
  MotionWrap(Resume, 'app__publications'),
  'resume',
  'app__whitebg',
);
