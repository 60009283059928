import React, { useState, useEffect, useCallback, useMemo } from "react";
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { images } from '../../constants';
import './Header.scss';
import TypeAnimation from 'react-type-animation';
import Particles, { ISourceOptions } from "react-tsparticles";
import Coding from "./coding.svg";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const About = () => {



  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
  window.addEventListener("resize", updateMedia);
  return () => window.removeEventListener("resize", updateMedia);
});
const options: ISourceOptions = {
  background: {
    color: "#fff",
  },
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: "push",
      },
      onHover: {
        enable: true,
        mode: "repulse",
      },
      resize: true,
    },
    modes: {
      bubble: {
        distance: 0,
        duration: 2,
        opacity: 0.8,
        size: 40,
      },
      push: {
        quantity: 0,
      },
      repulse: {
        distance: 50,
        duration: 0.4,
      },
    },
  },
  particles: {
    color: {
      value: "#000000",
    },
    links: {
      color: "#000000",
      distance: 200,
      enable: true,
      opacity: 0.5,
      width: 1,
    },
    collisions: {
      enable: false,
    },
    move: {
      direction: "clockwise",
      enable: true,
      outMode: "bounce",
      random: false,
      speed: 1,
      straight: true,
    },
    number: {
      density: {
        enable: true,
        value_area: 800,
      },
      value: 20,
    },
    opacity: {
      value: 0.5,
    },
    shape: {
      type: "circle",
    },
    size: {
      random: false,
      value: 2,
    },
  },
};
return (
  <>


  <motion.div
  whileInView={{ x: [-100, 0], opacity: [0, 1] }}
  transition={{ duration: 0.5 }}
  className="app__header-info"
  >
  <div className="app__header-badge">
    <div className="badge-cmp app__flex">

      <div style={{ marginLeft: 20 }}>
        <p className="largep-text">Hi there <span class="waving-hand">👋</span> </p>
        <h2 >I'm Brandon</h2>
        <p className="medp-text">and I'm passionate about</p>
        <TypeAnimation
        cursor={true}
        sequence={[
          'Science',
          1500,
          'Mathematics',
          1500,
          'Engineering',
          1500,
          'Software',
          1500,
          'UI/UX',
          1500,
        ]}

        repeat={Infinity}
        wrapper="h2"

        />
      </div>
    </div>


  </div>
  </motion.div>
  <div className="app__header app__flex">


  <img className='coding' src={Coding}/>


    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className="app__header-img"
    >
      <img src={images.profile} alt="profile_bg" />
  
    </motion.div>

    <div className="wrapper">
      <div className="loader">
        <div className="wave top-wave">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="wave bottom-wave">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

  </div>

  </div>
    </>
  )
};



export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);
